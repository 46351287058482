<template>
    <div class="competitionManagement">
        <div class="competitionManagement-header">
            <div class="selectCompetition">
                <span>训练状态</span>
                <el-select v-model="stateValue" placeholder="请选择" style="margin-left: 10px" @change="switchoverState">
                    <el-option
                            v-for="item in stateOptions"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div>
                <!--<el-button class="creationBtn" @click="editBalance">修改余额</el-button>-->
                <el-button class="creationBtn" @click="amendTrainTime">修改训练时间</el-button>
                <router-link :to="{name:'trainCreationCompetition'}"><el-button class="creationBtn" style="margin-left: 10px;">创建训练</el-button></router-link>
            </div>
        </div>
        <div class="competitionManagement-table">
            <el-table :data="tableData" border style="width: 100%;flex: 1;margin-top: 20px;height: calc(100vh - 320px);overflow-y: auto;border: 1px solid #EBEEF5;" :cell-style="{height: '60px',color:'#343442', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:400}" :header-cell-style="{fontWeight: '500', color: '#14141C', background: '#F3F4FA',height: '60px'}">
                <el-table-column label="训练名称" prop="name" align="center"></el-table-column>
                <el-table-column label="班级名称" prop="class_name" align="center"></el-table-column>
                <el-table-column label="训练状态" prop="status" align="center"></el-table-column>
                <el-table-column label="队伍人数" prop="s_num" align="center"></el-table-column>
                <el-table-column label="创建时间" prop="create_time" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #1222D8" @click="editBalance(scope.row)" v-if="scope.row.status === '未开始'">开始训练</el-button>
                        <el-button type="text" style="color: #1222D8" @click="endTrain(scope.row.id)">结束训练</el-button>
                        <el-button type="text" style="color: #1222D8" @click="delEdit(scope.row.id)">编辑</el-button>
                        <el-button type="text" style="color: #FE0001" @click="delRace(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>
        <el-dialog
                title="修改训练时间"
                :visible.sync="dialogVisible"
                width="30%">
            <el-form :model="form" :rules="rules" label-width="80px" label-position="left" ref="form">
                <el-form-item label="训练名称" prop="trainNme">
                    <el-select v-model="form.trainNme" placeholder="请选择" style="width:90%;" @change="selectTrain">
                        <el-option
                                v-for="item in tableData"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学生名称" prop="studentName">
                    <el-select v-model="form.studentName" placeholder="请选择" style="width:90%">
                        <el-option v-if="options.length && isSelectAll == 2" label="全部" :value="0" :key="0"></el-option>
                        <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="训练时长" prop="trainDate">
                    <el-input style="width: 40%;" v-model="form.trainDate" oninput="value=value.replace(/[^\d]/g,'')"></el-input><span style="margin-left: 6px">分钟</span>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="confirmAlter">确 定</el-button>
  </span>
        </el-dialog>
        <!--修改推广余额弹窗-->
        <el-dialog
                title="开始训练"
                :visible.sync="editBalanceDialog"
                :close-on-click-modal="false"
                width="508px"
                class="initialization-dialog">
            <div class="train-dialog-content">
                <el-form :model="editBalanceForm" ref="editBalanceForm" label-position="right" label-width="100px">
                    <el-form-item label="修改采购余额" prop="store_selection_amount">
                        <el-input style="width: 100%;" v-model="editBalanceForm.store_selection_amount" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>
                    <el-form-item label="修改展位余额" prop="booth_amount">
                        <el-input style="width: 100%;" v-model="editBalanceForm.booth_amount" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>
                    <el-form-item label="修改搜索余额" prop="search_adv_amount">
                        <el-input style="width: 100%;" v-model="editBalanceForm.search_adv_amount" oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editBalanceDialog = false">取 消</el-button>
                <el-button type="primary" @click="editBalanceBtn()" class="btn-blue">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
    import _ from "underscore";
    export default {
        name: "trainAdminData",
        data(){
            return {
                typeValue:'',
                stateValue:'',
                tableData:[],
                dialogVisible:false,
                stateOptions:[
                    {
                        id:0,
                        label:'全部'
                    },
                    {
                        id:1,
                        label:'未开始'
                    },
                    {
                        id:2,
                        label:'进行中'
                    },
                    {
                        id:3,
                        label:'已结束'
                    },],
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                form:{
                    trainNme:'',
                    studentName:'',
                    trainDate:''
                },
                options:[{
                    value:1,
                    label:'选项1'
                }],
                selectName:'',
                isSelectAll:1,
                rules: {
                    trainNme: [
                        { required: true, message: '请选择训练名称', trigger: 'change' }
                    ],
                    studentName: [
                        { required: true, message: '请选择学生名称', trigger: 'change' }
                    ],
                    trainDate: [
                        { required: true, message: '请输入训练时长', trigger: 'blur' },
                        { min: 1, max: 10, message: '长度在 1 到 10 个字符', trigger: 'blur' }
                    ]
                },
                //修改推广余额
                editBalanceDialog: false,
                editBalanceForm: {
                    search_adv_amount: '',
                    store_selection_amount: '',
                    booth_amount: ''
                },
                trainId: null,//训练id
                balanceList: []
            }
        },
        created() {
            this.getComList()
        },
        methods: {
            getComList(id){
                let param = {};
                if(id){
                    param = {
                        mode:2,
                        page: this.adminPages.currentPageNum,
                        limit: this.adminPages.eachPageNum,
                        type:'page',
                        status:id
                    };
                } else {
                    param = {
                        mode:2,
                        page: this.adminPages.currentPageNum,
                        limit: this.adminPages.eachPageNum,
                        type:'page'
                    };
                }
                this.$http.axiosGetBy(this.$api.comList, param, res=>{
                    if(res.code === 200){
                        this.tableData = res.data.data;
                        this.adminPages.total = res.data.total;
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            delEdit(id){
                this.$router.push({
                    path:'/trainadmin/trainmanage/trainadmincreationcompetitionone',
                    query: {
                        editRes:id
                    }
                })
            },
            delRace(row){
                this.$confirm('是否确认删除？','删除',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    this.$http.axiosGetBy(this.$api.delCom, {com_id:row.id}, res=>{
                        if(res.code === 200){
                            this.$message.success('删除成功！');
                            this.getComList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            endTrain(id){
                this.$confirm('确认结束该训练么？','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    this.$http.axiosGetBy(this.$api.endCompetition, {com_id:id}, res=>{
                        if(res.code === 200){
                            this.$message({
                                type: 'success',
                                message: '结束成功！',
                                duration:1000,
                                onClose:()=>{
                                    this.getComList()
                                }
                            });
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            selectTrain(val){
                this.isSelectAll = 2;
                this.form.studentName = '';
                this.$http.axiosGetBy(this.$api.getCom, {com_id:val}, res=>{
                    if(res.code === 200){
                        this.selectName = res.data.name;
                        this.$http.axiosGetBy(this.$api.studentAdminList, {class_id:res.data.c_id}, stures=>{
                            if(stures.code === 200){
                                if(stures.data.data){
                                    this.options = stures.data.data
                                }
                            } else {
                                this.$message.warning(stures.msg)
                            }
                        })
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            confirmAlter(){
                if(this.form.trainNme && this.form.studentName!=='' && this.form.trainDate){
                    let formData =new FormData();
                    // formData.append('name',this.selectName);
                    formData.append('duration',this.form.trainDate);
                    formData.append('s_id',this.form.studentName);
                    formData.append('com_id',this.form.trainNme);
                    this.$http.axiosPost(this.$api.editCDT, formData, res=>{
                        if(res.code === 200){
                            this.$message.success('修改成功！');
                            this.dialogVisible = false
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                } else {
                    this.$message.warning('请确保每项必填！')
                }
            },
            amendTrainTime(){
                this.dialogVisible = true;
                this.selectName = '';
                this.form.trainDate = '';
                this.form.studentName = '';
                this.form.trainNme = '';
            },
            switchoverState(val){
                this.getComList(val)
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getComList()
            },
            //修改推广余额
            editBalance(row) {
                this.trainId = row.id;
                if(row.has_operation === 1){
                  this.editBalanceDialog = true;
                  this.$http.axiosGetBy(this.$api.storeDrainage_editAmount, {competition_id: row.id}, (res) => {
                    if(res.code === 200){
                      this.balanceList = res.data;
                      let selectionGoods = _.find(this.balanceList, {type: 1});
                      this.editBalanceForm.store_selection_amount = selectionGoods.amount;
                      let searchAdv = _.find(this.balanceList, {type: 3});
                      this.editBalanceForm.search_adv_amount = searchAdv.amount;
                      let booth = _.find(this.balanceList, {type: 4});
                      this.editBalanceForm.booth_amount = booth.amount;
                    } else {
                      this.$message.warning(res.msg);
                    }
                  }, (err) => {
                    console.log(err);
                  })
                } else {
                  this.$http.axiosGetBy(this.$api.com_startTrain, {com_id:row.id}, (res)=>{
                    if(res.code === 200){
                      this.$message.success(res.msg);
                      this.getComList();
                    } else {
                      this.$message.warning(res.msg);
                    }
                  }, (err) => {
                    console.log(err)
                  })
                }
            },
            editBalanceBtn() {
                let formData = new FormData();
                formData.append('competition_id', this.trainId);
                if (this.editBalanceForm.search_adv_amount) {
                    formData.append('search_adv_amount', this.editBalanceForm.search_adv_amount);
                }
                if (this.editBalanceForm.store_selection_amount) {
                    formData.append('store_selection_amount', this.editBalanceForm.store_selection_amount);
                }
                if (this.editBalanceForm.booth_amount) {
                    formData.append('booth_amount', this.editBalanceForm.booth_amount);
                }
                this.$http.axiosPost(this.$api.storeDrainage_editAmount, formData, (res) => {
                    if(res.code === 200){
                        this.$message.success(res.msg);
                        this.editBalanceDialog = false;
                        this.getComList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-dialog__body{
        display: flex;
        flex-direction: column;
    }
    /deep/.el-form{
        /*margin: 0 auto;*/
    }
    /deep/.el-dialog__header{
        background: #0200D8;
        .el-dialog__title{
            color: #ffffff;
            font-size: 16px;
        }
        .el-dialog__headerbtn .el-dialog__close {
            color: #fff;
        }
    }
    /deep/.el-button--primary{
        background: #0200D8;
        border-color: #0200D8;
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .competitionManagement{
        height: 100%;
        padding: 30px;
        .competitionManagement-header{
            display: flex;
            justify-content: space-between;
            .selectCompetition{
                color: #343442;
                font-size: 14px;
                font-weight:500;
            }
            div{
                .creationBtn{
                    /*width: 80px;*/
                    height: 36px;
                    color: #ffffff;
                    background: #1222D8;
                    border-color: #1222D8;
                    line-height: 0;
                    padding: 0 12px;
                }
            }

        }
    }
</style>