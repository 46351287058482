<template>
    <div class="trainManage">
        <el-tabs v-model="activeName" type="card" class=" tabs-full">
            <el-tab-pane label="训练管理" name="trainAdmin">
                <trainAdminData />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import trainAdminData from './../../components/trainAdmin/trainAdminData/trainAdminData';
    export default {
        name: "TrainManage",
        components: {
            trainAdminData
        },
        data(){
            return {
                activeName:'trainAdmin'
            }
        }
    }
</script>

<style scoped lang="scss">
    .trainManage{
        height: 100%;
    }
</style>